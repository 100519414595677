import React, { useState } from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import { Button } from "../styles/baseStyles"
import { IoCall, IoMailSharp, IoMap } from "react-icons/io5"

export const contactQuery = graphql`
  {
    contact: contentfulContact {
      mail
      phone
      address {
        address
      }
    }
  }
`

export default function Contact({ data: { contact } }) {
  const [form, setForm] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  })
  const [status, setStatus] = useState(0) // 0 - intial, 1 - sending, 2 - sent, 3 - failed

  const handleChange = e => {
    setStatus(0)
    const { name, value } = e.target
    setForm(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (status === 1 || status === 2) return
    setStatus(1)
    let formData = new FormData()
    formData = { "form-name": "contact", ...form }
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setStatus(2)
        setForm({ name: "", email: "", subject: "", message: "" })
      })
      .catch(_ => setStatus(3))
  }

  return (
    <>
      <Seo title="Contact US" />
      <ContactContainer>
        <Column>
          <h4>Contact Us</h4>
          <h1>
            Take The Next Step <br /> To Your Project
          </h1>
          <ContactItem>
            <IoMailSharp /> <p>{contact.mail}</p>
          </ContactItem>
          <ContactItem>
            <IoCall /> <p>{contact.phone.join(", ")}</p>
          </ContactItem>
          <ContactItem>
            <IoMap />
            <p>{contact.address.address}</p>
          </ContactItem>
        </Column>
        <Column>
          <ContactForm name="contact">
            <input
              type="text"
              placeholder="Name*"
              name="name"
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Email*"
              name="email"
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Phone*"
              name="number"
              onChange={handleChange}
            />
            <textarea
              rows="5"
              placeholder="Message"
              name="message"
              onChange={handleChange}
            />
            <Button
              secondary
              loader={status === 1 ? 1 : 0}
              success={status === 2 ? 1 : 0}
              error={status === 3 ? 1 : 0}
              onClick={handleSubmit}
            >
              {status === 2
                ? "Submitted"
                : status === 3
                ? "Failed, Send Again"
                : "Submit"}
            </Button>
          </ContactForm>
        </Column>
      </ContactContainer>
    </>
  )
}

const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8rem 0.5rem 5rem;
  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
`
const Column = styled.div`
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  &:first-child {
    border: 3px solid #000;
    padding: 6rem;
    flex: 0 0 calc(50% - 9rem);
    margin-right: 7rem;
    h4 {
      font-size: 2rem;
      text-transform: uppercase;
    }
    h1 {
      font-size: 4rem;
      font-weight: 300;
      margin: 6rem 0 6rem;
    }
  }
  @media screen and (max-width: 1170px) {
    flex: 0 0 40%;
  }
  @media screen and (max-width: 780px) {
    &:first-child {
      margin-right: 4rem;
      padding: 4rem;
    }
  }
  @media screen and (max-width: 640px) {
    width: 85vw;
    &:first-child {
      margin-right: 0;
      padding: 4rem 8rem 6rem;
      margin-bottom: 4rem;
    }
  }
  @media screen and (max-width: 480px) {
    &:first-child {
      padding: 3rem 4rem 6rem;
    }
  }
  @media screen and (max-width: 430px) {
    width: 90vw;
  }
`
const ContactItem = styled.div`
  display: flex;
  align-items: center;
  p {
    white-space: pre-wrap;
  }
  svg {
    height: 2.4rem;
    width: 2.4rem;
    margin-right: 1.8rem;
  }
  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
  @media screen and (max-width: 480px) {
    p {
      font-size: 1.9rem;
    }
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`

const ContactForm = styled.form`
  margin-top: 1rem;
  input,
  textarea {
    font-size: 2rem;
    width: 100%;
    background: none;
    background-color: white;
    padding: 1rem 1rem 1rem 0.5rem;
    display: block;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 0;
    border-bottom: 1px solid #c7c7c7;

    ${props =>
      props.error &&
      css`
        border: 1px solid #f44336;
        border-radius: 5px;
        &::after {
          content: props?.errorMessage;
          display: block;
          color: #f44336;
        }
      `}

    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }
  button {
    display: flex;
    margin-top: 4rem;
  }
  @media screen and (max-width: 640px) {
    padding: 0 1.5rem;
  }
`
